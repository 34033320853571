import pino from "pino";
import { isProduction } from "@pam/common/environment";

const REDACT_IDENTIFIER = "[Redacted]";
const LOG_LEVEL = process.env.LOG_LEVEL ?? "debug";

// checks for the existence of a 'msg' arg in the first object passed, passes it as last arg
// so that browser console logs make sense
export function surfaceMsgString(args: Parameters<Console["log"]>) {
  const msg = (
    args[0] as {
      msg: string;
    }
  ).msg;
  if (msg) args.unshift(msg);
  return args;
}

// no client logging in production
// we could just return an empty function but we want it to be testable
export const browserLogFunctions = {
  info: (...args: Parameters<Console["info"]>) => {
    if (!isProduction()) console.info(...surfaceMsgString(args));
  },
  warn: (...args: Parameters<Console["warn"]>) => {
    if (!isProduction()) console.warn(...surfaceMsgString(args));
  },
  error: (...args: Parameters<Console["error"]>) => {
    if (!isProduction()) console.error(...surfaceMsgString(args));
  },
  debug: (...args: Parameters<Console["debug"]>) => {
    if (!isProduction()) console.debug(...surfaceMsgString(args));
  },
  trace: (...args: Parameters<Console["trace"]>) => {
    if (!isProduction()) console.trace(...surfaceMsgString(args));
  },
};

const isStackOrMessagePath = (paths: unknown[]) => {
  for (const path of paths) {
    if (typeof path === "string" && (path.includes("stack") || path.includes("message"))) {
      return true;
    }
  }
  return false;
};

// istanbul ignore next
const redact = (value: unknown, path: unknown[]) => {
  if (isStackOrMessagePath(path)) {
    // stack or message have a lot of valuable information, so we only redact very specific parts from it
    if (typeof value === "string" && value.includes("SharedAccessKey")) {
      // the event bridge library can log out the connection string, so we make sure to redact out the secret in it
      return value.replace(/SharedAccessKey=.*?;/g, `SharedAccessKey=${REDACT_IDENTIFIER};`);
    }
    // don't redact as it doesn't meet our custom censor rule(s) above
    return value;
  }
  // all other redaction paths automatically redact the content for the path
  return REDACT_IDENTIFIER;
};

export const redactionPaths = [
  '["authorization"]',
  '[*]["authorization"]',
  '[*][*]["authorization"]',
  '[*][*][*]["authorization"]',
  '["x-api-key"]',
  '[*]["x-api-key"]',
  '[*][*]["x-api-key"]',
  '[*][*][*]["x-api-key"]',
  '["apiKey"]',
  '[*]["apiKey"]',
  '[*][*]["apiKey"]',
  '[*][*][*]["apiKey"]',
  '["password"]',
  '[*]["password"]',
  '[*][*]["password"]',
  '[*][*][*]["password"]',
  '["message"]',
  '[*]["message"]',
  '[*][*]["message"]',
  '[*][*][*]["message"]',
  '["stack"]',
  '[*]["stack"]',
  '[*][*]["stack"]',
  '[*][*][*]["stack"]',
  '["licenseinfo"]',
  '[*]["licenseinfo"]',
  '[*][*]["licenseinfo"]',
  '[*][*][*]["licenseinfo"]',
  '["token"]',
  '[*]["token"]',
  '[*][*]["token"]',
  '[*][*][*]["token"]',
  '["cookie"]',
  '[*]["cookie"]',
  '[*][*]["cookie"]',
  '[*][*][*]["cookie"]',
  '["cookies"]',
  '[*]["cookies"]',
  '[*][*]["cookies"]',
  '[*][*][*]["cookies"]',
];

const baseLogger = pino({
  level: LOG_LEVEL,
  redact: {
    paths: redactionPaths,
    censor: redact,
  },
  serializers: { error: pino.stdSerializers.err },
  browser: { write: browserLogFunctions },
  // if in local dev mode, pretty print logs
  ...(process.env.NODE_ENV === "development"
    ? {
        transport: {
          target: "pino-pretty",
          options: {
            destination: 1,
            colorize: true,
            translateTime: true,
            ignore: "pid",
          },
        },
      }
    : undefined),
});

export const log = baseLogger;
