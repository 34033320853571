import type { Context } from "aws-lambda";
import { type Response } from "express";
import type { NextApiResponse } from "next";
import { log } from "./base-logger";
import { asyncLocalStorage } from "./async-local-storage";
// important for express server and pre-built apps to share the logger-bundle
export { asyncLocalStorage } from "./async-local-storage";

// Standard log to use for all requests
export const getLogger = () => {
  const store = asyncLocalStorage.getStore();
  if (store && store.logger) {
    return store.logger;
  }
  return log;
};

export const clearLogStore = () => {
  const store = asyncLocalStorage.getStore();
  if (store) {
    store.logger = undefined;
  }
};

export const addLogData = (data: Record<string, unknown>) => {
  const logger = getLogger();
  if (logger) {
    const store = asyncLocalStorage.getStore();
    if (store) store.logger = logger.child(data);
    return;
  }
};

export const addExpressContextToLogData = (response: Response) => {
  addLogData({
    requestId: response.get("X-Cuda-Request-Id"),
    xRayTraceId: response.get("X-Amzn-Trace-Id"),
  });
};

export const addLambdaContextToLogData = (context: Context) => {
  addLogData({
    requestId: context.awsRequestId,
    xRayTraceId: process.env._X_AMZN_TRACE_ID,
  });
};

export const addNextJsContextToLogData = (response: NextApiResponse) => {
  addLogData({
    requestId: response.getHeader("X-Cuda-Request-Id"),
    xRayTraceId: response.getHeader("X-Amzn-Trace-Id"),
  });
};
