import React, { createContext, useContext, useLayoutEffect } from "react";
import mixpanel from "mixpanel-browser";
import { isProduction } from "@pam/common/environment";
import { getLogger } from "@pam/common/logger";

export interface TrialsHubMixpanelProviderProps {
  token: string;
  userId: number;
  userName: string;
  userEmail: string;
  accountId: number;
  accountName: string;
  trialId?: string;
}

export const mixpanelProxy = {
  track: (...args: Parameters<typeof mixpanel.track>) => {
    try {
      getLogger().info({ args }, "Mixpanel tracking event");
      mixpanel.track(...args);
    } catch (error) {
      getLogger().info({ error, args }, "Ignoring mixpanel.track() failure");
    }
  },
  reset: () => {
    try {
      getLogger().info({}, "Mixpanel reset");
      mixpanel.reset();
    } catch (error) {
      getLogger().info({ error }, "Ignoring mixpanel.reset() failure");
    }
  },
} as typeof mixpanel;

export const TrialsHubMixpanelContext = createContext<typeof mixpanel>(mixpanelProxy);
export const TrialsHubMixpanelProvider = ({
  token,
  userId,
  userEmail,
  userName,
  accountId,
  accountName,
  trialId,
  children,
}: React.PropsWithChildren<TrialsHubMixpanelProviderProps>) => {
  // we useLayoutEffect here because we want to make sure this runs before any useEffect in the page
  useLayoutEffect(() => {
    try {
      getLogger().info({ userId, userName, userEmail, accountId, accountName, trialId }, "Initializing Mixpanel");

      mixpanel.init(token, { debug: !isProduction() });

      const distinctId = mixpanel.get_distinct_id() as unknown;
      mixpanel.alias(String(userId), String(distinctId));
      mixpanel.alias(String(userEmail), String(distinctId));
      mixpanel.people.set({
        $distinct_id: userId,
        $name: userName,
        $email: userEmail,
        $user_id: userEmail,
        bcc_user_id: userId.toString(),
        bcc_account_id: accountId.toString(),
        bcc_account_name: accountName,
        pam_trial_id: trialId,
      });
    } catch (error) {
      getLogger().error({ error }, "Error initializing mixpanel");
    }
  }, [token, userId, userName, userEmail, accountId, accountName, trialId]);

  return <TrialsHubMixpanelContext.Provider value={mixpanelProxy}>{children}</TrialsHubMixpanelContext.Provider>;
};
export const useMixpanel = () => useContext(TrialsHubMixpanelContext);
